import { textHeader, textParagraph, modalFooter } from "./Templates";
import { Form } from "./FormTemplate";
import { sendEmail, download } from "./Actions";
import { values, keys } from "../../../utils/array";

const filterForm = (data) => {
    if ("download" in data) {
        data.download = Array.isArray(data.download) ? data.download.length : !!data.download;
    }
    
    if ("r_company" in data)
    {
        data.company = data.r_company;
        delete data.r_company;
    }

    if ("r_jobTitle" in data)
    {
        data.jobTitle = data.r_jobTitle;
        delete data.r_jobTitle;
    }

    return data;
}

const handleSubmit = (data, forced = false) => {
    data = filterForm(data);

    let result = sendEmail(data);

    if (forced || data.download) {
        download();
    }

    return result;
}

const CommunityForm = (header, btnText, elements, hasFooterText) => [
    textHeader(header),
    Form(keys(elements), (d) => handleSubmit(d, true), btnText, null, null, values(elements)),
    modalFooter(hasFooterText)
];

const ProfessionalForm = (header, paragraph, btnText, elements, footerText) => [
    textHeader(header),
    textParagraph(paragraph, "fz-14 px-3 pb-4"),
    Form(keys(elements), handleSubmit, btnText, null, null, values(elements)),
    modalFooter(footerText)
];

const TeamsForm         = ProfessionalForm;
const EnterpriseForm    = ProfessionalForm;

export {CommunityForm, ProfessionalForm, TeamsForm, EnterpriseForm};