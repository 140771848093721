import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../utils/hooks";

function DropDown ({items, value, placeholder, name, id, onChange , onBlur}) {
    const [showItems, setShowItems] = useState();
    const [selected, setSelected] = useState(value);
    const mainRef = useRef();
    const inputRef = useRef();
    
    const getEvent = () => {
        let event = new Event('change', { bubbles: true });
        Object.defineProperty(event, 'target', {writable: false, value: inputRef.current});
        return event;
    }


    const changeShowItems = (b) => {
        if (showItems && !b)
            onBlur(getEvent());
        
        setShowItems(b);
    }

    const switchShowItems = () => changeShowItems(!showItems);

    useOnClickOutside(mainRef, () => changeShowItems(false));

    const itemSelect = (name) => {
        setSelected(name);
        if (onChange && typeof onChange === "function")
            setTimeout(() => onChange(getEvent()), 0);
    }

    return (
        <div id={id} className="dropdown" onClick={switchShowItems} ref={mainRef}>
            <input name={name} className="dropdown-select" type="text" value={selected} placeholder={placeholder} ref={inputRef} disabled/>
            <div className={`dropdown-list custom-scrollbar ${showItems ? "show" : "hide"}`}>
                {
                    items.map(item => {
                        return (
                            <div className={`dropdown-item ${selected === item ? "selected" : ""}`} onClick={() => itemSelect(item)}>{item}</div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default DropDown;