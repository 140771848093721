import React, {useRef, useState} from "react";
import Modal from "./Modal";
import { useOnClickOutside } from "../../../utils/hooks";
import CloseIcon from "../../icons/close"

const ClosingModal = ({ show, onClose, children }) => {
  
    const ref = useRef();
  
    useOnClickOutside(ref, () => close())
  
    const close = () => {
      onClose()
    }
  
    return (
      show &&
      <Modal>
        <div ref={ref}>
            <div className="flex justify-end p-5 pb-0">
                <button
                onClick={close}
                className="text-black justify-center flex w-4 sm:w-6 focus:outline-none"
                >
                <CloseIcon width="20" fill="#333" />
                </button>
            </div>
            {children}
        </div>
      </Modal>
    )
  }
  
  export default ClosingModal