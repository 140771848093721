import React from "react"

import PricingCard from "./PricingCard"

const PricingCardCollecttion = ({ data }) => {

  return (
    <div className="pricing-card-collect flex-1 self-stretch">
      <div className="pricing-card-collect-header mb-1">
        <div
          className="text-editor pricing-card-collect-heading"
          dangerouslySetInnerHTML={{ __html: data.header }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: data.paragraph }}
        />
      </div>
      <div className="flex justify-between gap-1 h-full pricing-cards">
        {data?.card?.map((card) => (
           <PricingCard key={card.header} card={card} hasModal={card.hasModal} hasThankYou={card.hasThankYou} ButtonAction={card.ButtonAction} />
        ))}
      </div>
    </div>
  )
}

export default PricingCardCollecttion
