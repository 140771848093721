import React from "react"


const Zoom = ({ children, className }) => {
    return (
        <div
            className={className}
            data-sal="zoom-in"
            data-sal-duration="450"
            data-sal-delay="200"
            data-sal-easing="ease-out">
            {children}
        </div>
    )
}

export default Zoom