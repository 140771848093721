import React, { useRef } from "react";

const Tooltip = ({ children, text }) => {
    const ref = useRef(null);

    const MouseEnter = () => {
        ref.current.style.opacity = 1;
        ref.current.style.marginLeft = "20px";
        ref.current.style.top = "-12px";
    }
    const MouseLeave = () => {
        ref.current.style.opacity = 0;
        ref.current.style.marginLeft = "10px";
        ref.current.style.top = "-12px";
    }
    return (
        <button
            className="relative flex items-start focus:outline-none cursor-auto"
            onMouseEnter={text && MouseEnter}
            onMouseLeave={text && MouseLeave}
        >
            <div
                className="absolute text-left hidden lg:block shadow-sm z-50 lg:w-40 xl:w-60 text-sm bg-blue text-white px-3 py-2 rounded-lg flex items-center transition-all duration-300 pointer-events-none"
                style={{ left: "100%", opacity: 0, }}
                ref={ref}
            >
                <div className="left-triangle" />
                {text}
            </div>
            {children}
        </button>
    );
}

export default Tooltip;