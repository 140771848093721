function intersect(keys, dict) {
    let result = {};


    
    keys.forEach(key => {
        if (key in dict)
            result[key] = dict[key];
    });
    
    return result;
}

function values(dict) {
    return keys(dict).map(e => dict[e]);
}

function keys(dict) {
    return Object.keys(dict);
}

function replace(dict, oldval, newval) {
    var index = Array.isArray(dict) ? dict.indexOf(oldval) : Object.values(dict).indexOf(oldval);

    if (index !== -1) {
        if (!Array.isArray(dict))
            index = Object.keys(dict)[index];

        dict[index] = newval;
    }
}

export { intersect, values, keys, replace };