import React from "react"
import Zoom from "../Shared/Animation/Zoom";
import Image from "../Shared/Image";

const TrustBox = ({ data }) => {

    return (
        <div className="container flex flex-col py-6 md:py-8 lg:py-12 bg-white px-4">
            <Zoom>
                <div className="text-editor trust-text" dangerouslySetInnerHTML={{ __html: data.trustUsTitle }} />
            </Zoom>
            <div className="flex mx-auto gap-8 md:gap-10 lg:gap-14 mt-4">
                {data.trustUsLogo && data.trustUsLogo.map((d, i) => (
                    d.urlLogo ?
                        <Zoom className="text-center">
                            <a className="block text-center" key={i} href={d.urlLogo} target="_blank" rel="noreferrer">
                                <Image
                                    media={d.image}
                                    className="flex-shrink-0 object-contain"
                                />
                            </a>
                        </Zoom> :
                        <Zoom className="text-center" key={i} >
                            <Image
                                media={d.image}
                                className="flex-shrink-0 object-contain h-auto"
                            />
                        </Zoom>
                ))}
            </div>
        </div>
    );
};

export default TrustBox;
