import React from "react"
import PricingFaq from "./PricingFaq"
import TrustBox from "../HomePage/TrustBox"
import PricingCardCollecttion from "./PricingCardCollection";
import FormFactory from "./Forms/FormFactory";
import { download } from "./Forms/Actions";

const Pricing = ({ data }) => {

  const trustData = {
    trustUsTitle: data.trustTitle,
    trustUsLogo: data.trustLogo,
  }

  const cardClickActions = {
    'community-free': download,
  }

  const thankYouEnabled = {
    'community-free': false,
  }

  const formMappings = {
    "community-free": data.pricingForm1,
    "professional-75": data.pricingForm2,
    "teams-99": data.pricingForm3,
    "enterprise-custom": data.pricingForm4,
  }

  data.pricingCardsCollect = data?.pricingCardsCollect?.map((item) => {item.card = item.card.map((i) => {i.form = FormFactory(i.btnId, formMappings[i.btnId]);return i;});return item;});

  data.pricingCardsCollect.forEach(item => {
    item.card.forEach(card => {
      card.hasModal = card.form !== undefined && card.form !== null;
      card.hasThankYou = !(card.btnId in thankYouEnabled) || thankYouEnabled[card.btnId];
      card.ButtonAction = cardClickActions[card.btnId];
    });
  });

  return (
    <>
      <div className="gradientBlue mx-auto">
      <div
          className="text-editor pricing-heading pt-24 mx-auto sm:px-0 px-6"
          dangerouslySetInnerHTML={{ __html: data.pricingPriceBox }}
        />
        <div
          className="lg:container text-editor w-11/12 lg:w-9/12 xl:w-7/12 mx-auto sm:px-0 px-10"
          dangerouslySetInnerHTML={{ __html: data.pricingPriceBoxBulletsHead }}
        />
        <div className="relative mb-24 p-3 flex gap-4 xl:flex-row flex-col mx-auto items-center justify-center w-23">
          {data?.pricingCardsCollect?.map((item, i) => <PricingCardCollecttion data={item} key={i}/>)}
        </div>
        <TrustBox data={trustData} />
      </div>
      <div className="lg:container px-4 md:px-8 mt-12 mb-36">
        <div
          className="text-editor"
          dangerouslySetInnerHTML={{ __html: data.pricingFaqTitle }}
        />
        <div className="grid gap-y-2 md:gap-y-8 gap-x-16 grid-cols-1 md:grid-cols-2 mt-4 md:mt-12">
          {data.pricingFaqAccordion &&
            data.pricingFaqAccordion?.map((d, i) => (
              <PricingFaq key={d.header} header={d.header} body={d.body} />
            ))}
        </div>
      </div>
    </>
  )
}

export default Pricing
