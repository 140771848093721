import React, { useState } from "react"
import BulletList from "./BulletList"
import ModalLayout from "../Shared/Modal/ModalLayout"
import TrailButton from "../Shared/TrailButton"
import thankYou from "./Forms/ThankYou";

const PricingCard = ({ card, hasModal = true, hasThankYou = true, ButtonAction = undefined}) => {
  const [showModal, setShowModal] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [isMobile, setIsMobile] = useState(typeof window !== "undefined" && window.innerWidth <= 425);
  const [showMore, setShowMore] = useState(false);
 
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 425);
  }

  if (typeof window !== "undefined") {
    window.addEventListener("resize", handleResize);
  }
  
  const openModal = () => {
    setShowModal(true);
  }

  const handleButton = () => {
    if (hasModal) {
      openModal();
      return;
    }
    if (typeof ButtonAction === 'function')
    {
      ButtonAction();
      openThankYou(7);
    }
  }

  const closeModal = () => {
    setShowModal(false);
  }

  const openThankYou = (seconds = -1) => {
    setShowThankYou(true);

    if (seconds !== -1) {
      setTimeout(closeThankYou, seconds * 1000);
    }
  }

  const closeThankYou = () => {
    setShowThankYou(false);
  }

  const swithcShowMore = () => {
    setShowMore(!showMore);
  }

  const afterSubmit = () => {
    closeModal();
    openThankYou(7);
  }

  return (
    <div className="relative flex flex-1 flex-col items-center bg-white pricing-card justify-start xl:mb-0 mb-4 px-4">
      {card.type && (
        <div className="absolute h-17 bg-blue w-full l-0"></div>
      )}
      <div
        className="text-editor pricing-text pt-4"
        dangerouslySetInnerHTML={{
          __html: card.header,
        }}
      />
      <div
        className="pricing-text self-start px-1 h-24 mb-2"
        dangerouslySetInnerHTML={{
          __html: card.paragraph,
        }}
      />
      <div className="h-28 mb-7 pricing-price-container">
        <div
          className="text-editor pricing-price flex justify-center"
          dangerouslySetInnerHTML={{
            __html: card.price,
          }}
        />
        <div className="w-full flex justify-center">
          <div
            className="text-editor mb-5 pricing-price-about"
            dangerouslySetInnerHTML={{
              __html: card.aboutPrice,
            }}
          />
        </div>
      </div>
      <TrailButton
        onClick={handleButton}
        type={card.btnType}
        text={card.btnText}
        id={card.btnId}
      />
      <div className="h-px bg-grey mt-7 mb-4 sm:my-10 w-10/12"></div>
      { isMobile && (
        <div className="mb-4 show-more-button self-start ml-6" onClick={swithcShowMore}> {showMore ? "- Show Less" : "+ Show More"}  </div>
      ) }

      <div className={!isMobile || showMore ? "show-more" : "hide-more"}>
        <BulletList key="check" data={card.checkList} type={"check"} />
        <BulletList key="bullet" data={card.bulletList} type={"bullet"} />
        { card.listHeader && 
          <div
            className="pricing-text-under self-start pl-3 py-3"
            dangerouslySetInnerHTML={{
              __html: card.listHeader,
            }}
          />
        }
        <BulletList key="list" data={card.list} type={"list"} />
        <div className="flex justify-center mt-8">
          {
            hasModal && 
            (
              <ModalLayout key={card.btnId} show={showModal} onClose={closeModal} layout={card.form} events={{"after:formSubmit": afterSubmit}} />
            )
          }
          {
            hasThankYou && 
            (
              <ModalLayout key={card.btnId + "__tnku"} show={showThankYou} onClose={closeThankYou} layout={thankYou} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default PricingCard
