import { useFormik } from "formik";
import React from "react";
import Dropdown from "../DropDownNative";
import * as yup from "yup"

const LayoutFactory = ({ element, events }) => {
    if (!("type" in element))
        throw new Error(`Don't have any type to create an element`);
    if (!("properties" in element))
        throw new Error(`Don't have any property to create ${element.type} element`);

    if (!(element.type in layouts))
        throw new TypeError("Unknown Element Type - " + element.type);
    
    if (!("pocket" in element.properties))
        element.properties.pocket = {};

    return layouts[element.type]({props: element.properties, events: events});
}

const ButtonElement = ({ props, events }) => {
    if (!("type" in props))
        props.type = "submit";

    switch (props.type) {
        case "submit":
            return (<input type="submit" id={props.id} value={props.value} name={props.name} className={props.styles?.join(' ')} {...props.events} />);
        default:
            throw new TypeError("Unknown Button Type - " + props.type);
    }
}

const InputElement = ({ props, events}) => {
    let result;
    if (!("type" in props))
        props.type = "text";

    switch (props.type) {
        case "textarea":
            result = (<textarea id={props.id} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.pocket.onChange} onBlur={props.pocket.onBlur}></textarea>);
            break;
        case "text":
        case "password":
            result = (<input id={props.id} type={props.type} placeholder={props.placeholder} name={props.name} value={props.pocket.value(props.name)} onChange={props.pocket.onChange} onBlur={props.pocket.onBlur}/>);
            break;
        case "checkbox":
        case "radio":
            result =
                (
                    <>
                        <input id={props.id} type={props.type} name={props.name} onChange={props.pocket.onChange} onBlur={props.pocket.onBlur} defaultChecked={props.pocket.value(props.name)}/>
                        <label htmlFor={props.name} >{props.placeholder}</label>
                    </>
                );
            break;
        case "dropdown":
            result = (<Dropdown name={props.name} items={props.items} placeholder={props.placeholder} value={props.pocket.value(props.name)} onChange={props.pocket.onChange}  onBlur={props.pocket.onBlur} />)
            break;
        default:
            throw new TypeError("Unknown Input Type - " + props.type);
    }
    
    let classes = props.styles?.join(' ') + " input-control ";
    classes += props.error ? "fail" : "success";

    return (
        <div className={classes} data-for={props.id}>
            {result}
            <p className="error-message">{props.pocket.touched(props.name) && props.pocket.error(props.name)}</p>
        </div>
    )
}

const TextElement = ({ props, events }) => {
    if (!("type" in props))
        props.type = "lable";

    switch (props.type) {
        case "link":
            return (<a id={props.id} name={props.name} className={props.styles?.join(' ')} href={props.href}>{props.text}</a>)
        case "header":
            let Tag = 'h' + props.size;
            return (<Tag id={props.id} name={props.name} className={props.styles?.join(' ')} dangerouslySetInnerHTML={{ __html: props.text }}></Tag>);
        case "paragraph":
            return (<p id={props.id} name={props.name} className={props.styles?.join(' ')} dangerouslySetInnerHTML={{ __html: props.text }}></p>)
        case "lable":
            return (<label id={props.id} name={props.name} className={props.styles?.join(' ')} htmlFor={props.for} dangerouslySetInnerHTML={{ __html: props.text }}></label>);
        default:
            throw new TypeError("Unknown Label Type - " + props.type);
    }
}

const FormElement = ({ props, events }) => {

    const submit = () => {
        let [before, after] = [events["before:formSubmit"], events["after:formSubmit"]];

        if (typeof before === "function")
            before();

        props.onSubmit(formik.values);

        if (typeof after === "function")
            after();
    }

    let schema = "validations" in props ? formikValidation(props.validations) : undefined;
    const formik = useFormik({
        initialValues: props.initial,
        validationSchema: schema,
        onSubmit: submit
    });

    if (!("type" in props))
        props.type = "form";

    switch (props.type) {
        case "form":
            return (
                <form id={props.id} className={props.styles?.join(" ")} onSubmit={formik.handleSubmit}>
                    {props.elements.map((element, id) => {
                        element.properties.pocket = props.pocket;
                        element.properties.pocket.value = (name) => formik.values[name];
                        element.properties.pocket.error = (name) => formik.errors[name];
                        element.properties.pocket.touched = (name) => formik.touched[name];
                        element.properties.pocket.onChange = formik.handleChange;
                        element.properties.pocket.onBlur = formik.handleBlur;

                        return (
                            <LayoutFactory key={id} element={element} />
                        )
                    })}
                </form>
            );
        default:
            throw new TypeError("Unknown Form Type - " + props.type);
    }
}

const SectionElement = ({ props, events }) => {
    if (!("type" in props))
        props.type = "div";

    switch (props.type) {
        case "div":
            return (
                <div id={props.id} className={props.styles?.join(" ")}>
                    {props.elements.map(element => {
                        element.properties.pocket = props.pocket;
                        return (
                            <LayoutFactory element={element} />
                        )
                    })}
                </div>
            );
        default:
            throw new TypeError("Unknown Form Type - " + props.type);
    }
}

const formikValidation = (scheme) => {
    let res = {};
    Object.keys(scheme).forEach(key => {
        let val;

        switch(scheme[key].type) {
            case "string":
                val = yup.string();
                break;
            case "date":
                val = yup.date();
                break;
            case "number":
                val = yup.number();
                break;
            case "boolean":
                val = yup.boolean();
                break;
            default:
                throw new TypeError("Unknown Scheme Type - " + scheme.type);
        }

        scheme[key].options.forEach(option => {
            switch (option.name) {
                case "min":
                    val = val.min(option.value, option.message);
                    break;
                case "max":
                    val = val.max(option.value, option.message);
                    break;
                case "required":
                    val = val.required(option.message);
                    break;
                case "email":
                    val = val.email();
                    break;
                case "equal":
                    val = val.oneOf([yup.ref(option.value)], option.message);
                    break;
                case "pattern":
                    val = val.matches(option.value, option.message)
                    break;
                case "typeError":
                    val = val.typeError(option.message)
                    break;
                default:
                    throw new TypeError("Unknown Option Type - " + option.name);
            }
        });

        res[key] = val;
    });

    return yup.object().shape(res);
}

const layouts = {
    "button": ButtonElement,
    "input": InputElement,
    "text": TextElement,
    "form": FormElement,
    "section": SectionElement,
}

export default LayoutFactory