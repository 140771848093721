import React from "react";
import ClosingModal from "./ClosingModal";
import LayoutFactory from "./LayoutFactory";

const ModalLayout = ({ show, onClose, layout, events }) => {
    if (layout == null)
        return null;
    layout = layout.map(element => {
        if (!("pocket" in element.properties))
            element.properties.pocket = {};
        element.properties.pocket.close = onClose;

        return element;
    });
    
    return (
        <ClosingModal show={show} onClose={onClose}>
            {layout.map((element, id) => {
                return (
                    <LayoutFactory key={id} element={element} events={events} />
                )
            })}
        </ClosingModal>
    )
  }
  
  export default ModalLayout