import { intersect, values } from "../../../utils/array";
import { validations, initials, inputs } from "./Consts";

const submitButton = (text) => {
    return {
        type: "section",
        properties: {
            type: "div",
            styles: [
                "flex flex-row justify-center"
            ],
            elements: [
                {
                    type: "button",
                    properties: {
                        type: "submit",
                        styles: [
                            "flex rounded-lg min-w-140 bg-blue text-white border-blue justify-center items-center p-2 mt-10 mb-4"
                        ],
                        value: text
                    }
                }
            ]
        }
    }
}

const Form = (elements, onSubmit, submitText, forcedValidations = null, forcedInitials = null, forcedElements = null) => {
    return {
        type: "form",
        properties: {
            initial: forcedInitials ?? intersect(elements, initials),
            validations: forcedValidations ?? intersect(elements, validations),
            onSubmit: onSubmit,
            styles: [
                "px-6",
                "sm:px-10",
                "flex",
                "flex-col"
            ],
            elements: [
                {
                    type: "section",
                    properties: {
                        type: "div",
                        styles: [
                            "grid sm:gap-x-8 lg:gap-x-12 gap-y-8 grid-cols-1 sm:grid-cols-2 relative"
                        ],
                        elements: forcedElements ?? values(intersect(elements, inputs))
                    }
                },
                submitButton(submitText)
            ]
        }
    };
}

export {Form, submitButton};