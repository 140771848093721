import React from "react"
import Tooltip from "../Shared/Tooltip";

const BulletList = ({ data, type }) => {

    return (
        <>
            <div className="self-start flex pl-3 justify-center">
                {data &&
                    <ul className="grid grid-cols-none sm:grid-cols-1 gap-x-8 list-none">
                        {data.map((list, id) => (
                            <li className={`sm:pr-3 sm:pr-0 pricing-listitem-${type} flex relative`} key={id}>
                                <Tooltip text={list.tooltip}>
                                    <div className="text-editor" dangerouslySetInnerHTML={{ __html: list.text }}/>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </>
    );
};

export default BulletList

