import classNames from "classnames";
import React, { useState } from "react";
import ArrowBottom from "../icons/arrow-bottom";


const PricingFaq = ({ header, body }) => {
    const [isToggled, setIsToggled] = useState(false);

    return (
        <div>
            {/* Desktop */}
            <div className="hidden flex-col md:flex">
                <p className="font-bold text-lg mb-2">{header}</p>
                <div className="text-editor" dangerouslySetInnerHTML={{ __html: body }} />
            </div>
            {/* End Desktop */}
            {/* Mobile */}
            <div className="flex flex-col md:hidden">
                <button className="focus:outline-none flex w-full justify-between items-start py-3 px-2"
                    onClick={() => setIsToggled(!isToggled)} aria-label="Collapse">
                    <div className="text-left font-semibold mr-5">
                        {header}
                    </div>
                    <div className={classNames
                        ("origin-center transform mt-1 flex-shrink-0",
                            { "rotate-0": isToggled, "rotate-180": !isToggled, },
                        )}>
                        <ArrowBottom />
                    </div>
                </button>
                <div className={classNames
                    ("py-3 px-2",
                        { "flex": isToggled, "hidden": !isToggled, },
                    )}>
                    <div className="flex flex-col">
                        <div className="text-left">
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                        </div>
                    </div>
                </div>
            </div>
            {/* End Mobile */}
        </div>
    )
}


export default PricingFaq;