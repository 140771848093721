import React from "react"
import { useLockBodyScroll } from "../../../utils/hooks"

const Modal = ({ children }) => {
  useLockBodyScroll()

  return (
    <div className="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div
        className="custom-scrollbar bg-white rounded-none sm:rounded-xl shadow-lg overflow-auto max-h-90 w-11/12 lg:w-5/12"
      >
        {children}
        <div className="rounded-none sm:rounded-xl relative b-0 bg-blue-darkTwo h-2"></div>
      </div>
    </div>
  )
}

export default Modal