import React from "react"
import { graphql } from 'gatsby'
import Pricing from "../components/Pricing/Pricing"
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";

const PricingPage = ({ data }) => {
  const pricingData = data?.allStrapiPricing?.edges[0]?.node;

  return (
    <>
      <SEO seo={pricingData.metaData} />
      <LayoutHeadFoot>
        <Pricing data={pricingData} />
      </LayoutHeadFoot>
    </>
  );
};

export const query = graphql`
query PricingQuery {
  allStrapiPricing {
    edges {
      node {
        metaData {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              publicURL
            }
          }
        }
        pricingForm1 {
          formName
          header
          footer
          btntext
          installationLink
          fields {
            field
            text
          }
        }
        pricingForm2 {
          formName
          header
          footer
          btntext
          installationLink
          fields {
            field
            text
          }
        }
        pricingForm3 {
          formName
          header
          paragraph
          footer
          btntext
          fields {
            dropDown
            field
            text
          }
        }
        pricingForm4 {
          formName
          header
          paragraph
          footer
          btntext
          fields {
            dropDown
            field
            text
          }
        }
        pricingPriceBox
        pricingPriceBoxBulletsHead
        pricingBoxBottomText
        twoCollBannerText
        pricingFaqTitle
        pricingFaqAccordion {
          body
          header
          id
        }
        pricingCardsCollect {
          header
          paragraph
          card {
            btnId
            btnType
            btnText
            type
            header
            paragraph
            price
            aboutPrice
            listHeader
            checkList {
              text
            }
            bulletList {
              text
            }
            list {
              text
            }
            dropdownType
          }
        }
        trustTitle
        trustLogo {
          urlLogo
          image {
            alternativeText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(formats: [AUTO])
              }
            }
          }
        }
      }
    }
  }
}`

export default PricingPage

